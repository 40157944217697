import React from 'react'

import phoneicon from "../assets/icons/phone-blue-circle.svg";
import mapicon from "../assets/icons/location-blue-circle.svg";
import mailicon from "../assets/icons/mail-blue-circle.svg";
import hoursicon from "../assets/icons/hours-blue.svg";

const Home = () => {
    return (
        <section className="home">
            <div className="home__hero">
                <div className="home__hero-warning">
                {/* <p className="home__philosophy-text home__philosophy-text--alert">
                        Covid 19 Protocols Currently in Effect at Clinic
                </p> */}
                </div>
            </div>
            <div className="home__philosophy">
                
                <p className="home__philosophy-text">At Harbourside Medical Clinic, our philosophy of care
                is a commitment to provide quality medical health care
                in a friendly, confidential and inclusive environment.
                </p>
                <p className="home__philosophy-text">Our doctors and staff
                work very hard to support our patients needs with compassion and integrity.</p>
            </div>
            <div className="home__info">
                <div className="home__address-section">
                    <div className="home__address">
                        <div className="home__address-icon">
                            <img src={phoneicon}
                                alt="Phone Number"
                            />
                        </div>
                        <div className="home__address-p">
                            <p>Phone: <a href="tel:250-285-2265">(250) 285-2265</a></p>
                            <p>Fax: (250) 285-2264</p>
                            <p className="home__address-p-message">Phone lines open at 9am for <br/>same day urgent appointments</p>
                        </div>
                    
                    </div>
                    <div className="home__address">
                        <div className="home__address-icon">
                            <img
                                src={mailicon}
                                alt="Email"
                            />
                        </div>  
                        <div className="home__address-p">
                            <p><a href="mailto:office@hrbmc.ca">office@hrbmc.ca</a></p>
                        </div>
                    </div>
                    <div className="home__address">
                        <div className="home__address-icon">
                            <img src={mapicon} alt="Location"/>
                        </div>
                        <div className="home__address-p">
                            <p>672 Plaza Rd</p>
                            <p className="home__address-break">(Beside Cove Pharmacy)</p>
                            <p>PO Box 250</p>
                            <p>Quathiaski Cove, BC</p>
                            <p>V0P 1N0</p>
                        </div>
                    </div>
                    
                </div>
                <div className="home__hours-section">
                    <div className="home__hours-container">
                    <div className="home__hours-icon">
                    <img src={hoursicon} alt="Hours"
                        />
                    </div>
                    <div className="home__hours">
                        <div className="home__hours-days">
                            <p>Sun</p>
                            <p>Mon</p>
                            <p>Tues</p>
                            <p>Wed</p>
                            <p>Thurs</p>
                            <p>Fri</p>
                            <p>Sat</p>
                        </div>
                        <div className="home__hours-times">
                            <p>Closed</p>
                            <p>9:30am - 4:30pm*</p>
                            <p>9:30am - 4:30pm*</p>
                            <p>9:30am - 4:30pm*</p>
                            <p>9:30am - 4:30pm*</p>
                            <p>9:30am - 4:30pm*</p>
                            <p>Closed</p>
                        </div>
                        </div>
                    </div>
                    <div className="home__hours-disclaimer">
                        <p className="home__hours-disclaimer-text">*Closed for lunch from 1pm - 2pm</p>
                        <p className="home__hours-disclaimer-text">Hours are subject to change, office should be phoned to confirm on any given day.</p>
                        <p>Closed for Statutory Holidays</p>
                    </div>
                </div>
            </div>
            <div className="home__philosophy">
                <p className="home__philosophy-text">We respectfully acknowledge that we live and work on the traditional territory of the Laich-Kwil-Tach people of the Wei Wai Kum First Nations.</p>
            </div>
        </section>
    )
}

export default Home;
