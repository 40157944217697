import React from 'react'

const Faq = () => {
    return (
        <>
            <div className="page__title-container">
            <p className="page__title">FAQ</p>
            </div>
            <section className="faq">
                <div className="faq__side">
                <div className="faq__card">
                    <div className="faq__question">
                        <p>Can I call in for a <span className="faq__emphasis">same day appointment?</span></p>
                    </div>
                    <div className="faq__answer">
                        <p>YES - if it’s for matters of an URGENT nature such as sudden illness, chest pain, bleeding, difficulty breathing, head injury, trauma, or care for high risk patients such as young children or elderly.   The phone lines open at 9:00 am for these urgent appointments.</p>
                    </div>
                </div>
                <div className="faq__card">
                    <div className="faq__question">
                        <p>Can I <span className="faq__emphasis">book ahead</span> for an appointment?</p>
                    </div>
                    <div className="faq__answer">
                        <p>YES – patients need to set up future appointments for routine things such as prescription refills, follow up of lab results, general non-urgent health concerns, physicals, biopsies, Marine Medicals and Drivers Medicals.  Please do not call in right at 9:00 am for future appointment as the lines are very busy.</p>
                    </div>
                </div>
                <div className="faq__card">
                    <div className="faq__question">
                        <p>Can I come in as a <span className="faq__emphasis">Walk-In</span> Patient?</p>
                    </div>
                    <div className="faq__answer">
                        Unfortunately our physicians have a full patient load, and cannot accept walk-ins. However, we will do our best to accommodate urgent situations.
                        {/* <p>Appointments are preferred, but if we have a second doctor working on that day, we may be able to fit you in.  Please inquire at the front desk to check on the availability option on a given day.</p> */}
                        </div>
                </div>
                <div className="faq__card">
                    <div className="faq__question"><p>Length of appointment?</p></div>
                    <div className="faq__answer faq__answer--list-mobile">
                        <p>Please advise reception if you are booking any of the following to allow for proper set up and longer time allotments:</p>
                        <br/>
                        <ul>
                        <li>Physicals</li>
                        <li>Drivers Medical</li>
                        <li>Seafarers Medical</li>
                        <li>Biopsies</li>
                        <li>Counselling</li>
                        <li>Long Forms</li>
                        </ul>
                        </div>
                        <div className="faq__answer faq__answer--list-desktop">
                        <p>Please advise reception if you are booking any of the following to allow for proper set up and longer time allotments:</p>
                        <br/>
                        <ul>
                            <div>
                                <li>Physicals</li>
                                <li>Drivers Medical</li>
                                <li>Seafarers Medical</li>
                            </div>
                            <div>
                                <li>Biopsies</li>
                                <li>Counselling</li>
                                <li>Long Forms</li>
                            </div>
                        </ul>
                        </div>
                    </div>
                </div>
                <div className="faq__side">
                <div className="faq__card">
                    <div className="faq__question">
                        <p>Do I have to see a doctor</p>
                        <p>to get a <span className="faq__emphasis">refill on my prescription?</span></p>
                    </div>
                    <div className="faq__answer">
                        <p>YES – please plan to book such at least 3 weeks in advance to ensure you get in before you run out of your medications.</p>
                    </div>
                </div>
                <div className="faq__card">
                    <div className="faq__question">
                        <p>WCB</p>
                    </div>
                    <div className="faq__answer">
                        <p>Advise reception upon each arrival if this visit is for a work related injury.</p>
                    </div>
                </div>
                <div className="faq__card">
                    <div className="faq__question">
                        <p>Missed Appointment Fees</p>
                    </div>
                    <div className="faq__answer">
                        <p>Cancellations must be made by 9am on the day of the appointment, or the following fees will be charged:</p>
                        <ul>
                        <li>Routine Visit: $30.00</li>
                        <li>Long Procedure Visit: $50.00</li>
                        </ul>
           	<p>If you can not keep your appointment, be sure to call and cancel it, as missed appointment fees will be charged.</p>
                    </div>
                </div>
                <div className="faq__card">
                    <div className="faq__question">
                        <p>Does reception need to know</p><p>the <span className="faq__emphasis">nature of my appointment?</span></p>
                    </div>
                    <div className="faq__answer">
                        <p>The choice is yours on what you share, but the more information that you provide enables us to triage care. It also allows us to set up any medical equipment, prepare paperwork, and/or determine the length of appointment time required. </p>
                    </div>
                </div>
                </div>
            </section>
            <section className="general">
                <div className="general__title-container">
                    <p className="general_title">General Info</p>
                </div>
                <div className="general__item">
                    <p>We try our best to accommodate all patients, but our priority is always for attending to serious medical situations.</p>
                </div>
                <div className="general__item">
                    <p>We reserve the right to refuse service for disrespectful or rude behaviour.</p>
                </div>
                <div className="general__item">
                    <p>Everything in this office is kept in strict confidentiality and Rules of Privacy apply to correspondence.</p>
                </div>

            </section>
        </>
    )
}

export default Faq
